import { Injectable } from '@angular/core';
import { UUID } from "angular2-uuid";
import { BehaviorService } from './_services/behavior.service';

declare let register: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public propConfs$: any;
  public readonly relativeUrl: string = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  // playbox.global.default
  /*---------- PROJECT VARIABLES ----------
  public readonly serviceId: number = 0;
  public readonly operatorId: number = 0;
  public readonly productId: number = 0;
  public readonly countryId: number = 0;
  public readonly prefix: string = null;
  public readonly langs: string[] = ['en'];
  public readonly userName: boolean = false;
  public readonly pushNotif: boolean = false;
  public readonly appDownload: string = null;
  public readonly infoPages = {
    en: {
      terms: null,
      about: null,
      help: null
    }
  }
  public readonly neoIds = {
    howManyMainCats: 4,
    channelTypeId: 17,
    en: {
      slideShowCatID: 440116,
      channelId: null,
      viewId: null
    }
  }
  public readonly loginFlow = {
    pinConfirmation: true,
    queryToken: null,
    headers: null,
    decriptHeaders: false,
    decriptQueryToken: false,
    haveHttps: false,
    'sub.access.status': 'ACTIVE',
    'sub.access.not.billet': false,
    'sub.access.grace': true
  }
  public readonly subscriptionFlow = {
    outsideSubscription: null,
    allowUnsubscription: true
  }
  public readonly event = {
    timweBi: false,
    analyticsId: null
  }
  ---------- PROJECT VARIABLES ----------*/

  constructor(private beh: BehaviorService) {
    this.beh.propConfs.subscribe(props => { this.propConfs$ = props });
  }

  public initPush(newMsisdn: string = null) {
    let msisdn = !!JSON.parse(sessionStorage.u || '{}').m ? atob(JSON.parse(sessionStorage.u).m) : null;
    this.propConfs$.pushNotif && !!this.propConfs$.productId && register(this.propConfs$.productId, newMsisdn || msisdn, this.propConfs$.operatorId, this.propConfs$.countryId, this.propConfs$.serviceId);
  }

  public generateDeviceId() {
    let user = JSON.parse(localStorage.u || '{}');

    if (!!user.d) {
      return user.d;
    } else {
      user.d = UUID.UUID();
      window.localStorage.u = JSON.stringify(user);
      return user.d;
    }
  }

  public generateSessionId() {
    let userSession = JSON.parse(sessionStorage.u || '{}');

    if (!!userSession.s) {
      return userSession.s;
    } else {
      userSession.s = UUID.UUID();
      window.sessionStorage.u = JSON.stringify(userSession);
      return userSession.s;
    }
  }
}
