import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviorService {

  public _categories = new BehaviorSubject<any>(null);
  public _sideNav = new BehaviorSubject<boolean>(false);
  public _userPopUp = new BehaviorSubject<'pushNotif' | 'insertMsisdn' | 'insertPin' | 'profile' | 'subscribe' | 'subscribeSuccess' | 'subscribeError' | 'noCredit' | 'subscribePending'>(null);
  public apiRequest = new BehaviorSubject<string>(null);
  public isInHome = new BehaviorSubject<boolean>(false);

  public userSubscription = new BehaviorSubject<any>(null);
  public portalClubs = new BehaviorSubject<any>(null);
  public propConfs = new BehaviorSubject<any>(null);

  public slideCallResp_ = new BehaviorSubject<any>(null);
  public homeContCallResp_ = new BehaviorSubject<any>(null);

  constructor() { }

  public toggleSideNav(act = null) {
    act == null ? this._sideNav.next(!this._sideNav.value) : this._sideNav.next(act);
  }
}
