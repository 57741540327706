import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { UUID } from "angular2-uuid";
import { BaseRequestBody } from '../_models/auth.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

declare let siteName: any;

@Injectable({
  providedIn: 'root'
})
export class MainService {

  public readonly relativeUrl: string = `${location.protocol}//${location.host}`;

  constructor(
    private router: Router
  ) { }

  public userStorage() {
    return JSON.parse(localStorage.u || '{}');
  }

  public getQueryParams() {
    let result: any = {};
    let _params = new URLSearchParams(location.search) as any;
    for (let param of _params) { result[param[0]] = param[1] }
    return result;
  }

  public callHeaders(useJwt: boolean = true) {
    let user = this.userStorage();
    let searchParams = this.getQueryParams();
    let path = location.pathname.replace('/', '').split('/');

    if (!!searchParams.ut) {
      user.j = atob(searchParams.ut);
      useJwt = true;
      delete searchParams.ut;
      this.router.navigate(path, { queryParams: searchParams });
    }

    let httpOptions = new HttpHeaders().set('Content-Type', 'application/json');
    useJwt && user.j && (httpOptions = httpOptions.set('jwt', user.j));
    return httpOptions;
  }

  public requestPreset() {
    let requestBody: BaseRequestBody = {
      connectionType: this._connection() ? this._connection().effectiveType : undefined,
      lang: this.userStorage().l || undefined,
      deviceUuid: this.userStorage().d || undefined,
      userAgent: navigator.userAgent,
      referer: environment.production ? location.hostname : siteName(),
      url: environment.production ? location.href : siteName(),
      timestamp: new Date().getTime(),
      transactionId: UUID.UUID(),
      context: {},
      additionalParameters: {}
    }

    return requestBody;
  }

  public mapResponse(response) {
    return !response.inError && response.responseCode == '200' ? { ...response.responseData, ...{ additionalFields: response.additionalFields } } : null;
  }

  private _connection() {
    return navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection'] || undefined
  }
}
