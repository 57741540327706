<div id="headerFixed" [class.trans]="inHomePage && !sideNavOpen && isOnTop" [class.hided]="inHomePage && !isOnTop">
    <div class="login-header">
        <div class="container">
            
            <!-- <a class="login" (click)="beh._userPopUp.next('insertMsisdn')" *ngIf="!loggedIn && !global.propConfs$.allowAccess && global.propConfs$.otp">{{ "user.login" | translate }}</a> -->
            <!-- <a class="profile" (click)="beh._userPopUp.next('profile')" *ngIf="loggedIn && !global.propConfs$.allowAccess && global.propConfs$.otp"><i class="far fa-user"></i></a> -->
            <a class="login" (click)="beh._userPopUp.next('insertMsisdn')" *ngIf="!loggedIn && !global.propConfs$.allowAccess && global.propConfs$.otp">{{ "user.subscribe" | translate }}</a>
            <a class="profile" (click)="beh._userPopUp.next('profile')" *ngIf="loggedIn && isUserSubscribed && !global.propConfs$.allowAccess"><i class="far fa-user"></i></a>
            <a class="login" (click)="beh._userPopUp.next('profile')" *ngIf="loggedIn && !isUserSubscribed && !global.propConfs$.allowAccess">{{ "user.subscribe" | translate }}</a>
            <a class="login" (click)="beh._userPopUp.next('subscribe')" *ngIf="!isUserSubscribed && !global.propConfs$.allowAccess && !global.propConfs$.otp">{{ "user.subscribe" | translate }}</a>
                
            <img *ngIf="!!global.propConfs$.operatorLogo" class="logo" [src]="global.propConfs$.operatorLogo" [routerLink]="['']" alt="Logo Operator">
            <img *ngIf="!!global.propConfs$.portalLogo" class="logo" [src]="global.propConfs$.portalLogo" [routerLink]="['']" queryParamsHandling="merge" (click)="beh.toggleSideNav(false)" alt="Logo Playbox">

            <div *ngIf="global.propConfs$.langs.length > 1" class="langs">
                <a *ngFor="let local of global.propConfs$.langs" (click)="langService.changeLang(local)" [class.activeLang]="lang$ == local">{{ local | uppercase }}</a>
            </div>

        </div>
    </div>

    <div class="header" [class.hideSearch]="!doSearch">
        <div class="container">
            <div class="openMenuBox">
                <div class="openMenu icons" (click)="beh.toggleSideNav(); doSearch = false"><i class="fas fa-bars"></i></div>
            </div>
            <div class="headerSearchBox">
                <input #searchInput class="header-search" type="text" (keydown.enter)="search(); beh.toggleSideNav(false)"><span (click)="doSearch = false"><i class="fas fa-times"></i></span>
            </div>
            <div class="searchBtnBox">
                <div class="searchBtn icons" (click)="search(); beh.toggleSideNav(false)"><i class="fas fa-search"></i></div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showScroll" id="scrollTop" (click)="scrollTop()"><i class="fas fa-chevron-up"></i></div>