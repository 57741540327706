import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorService } from '../_services/behavior.service';
import { map } from 'rxjs/operators';
import { GlobalService } from '../global.service';
import { Authentication } from 'projects/authentication/auth.core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanLoad {

  constructor(
    private beh: BehaviorService,
    private global: GlobalService,
    private auth: Authentication,
    private router: Router
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    let userSess = JSON.parse(sessionStorage.u || '{}');

    if (this.global.propConfs$) {
      if (!!userSess.m && !this.global.propConfs$.allowAccess) {
        // this.beh.mainLoader.next(true);
        if (this.global.propConfs$.subscriptionNeeded) {
          return this.auth.subscription({ status: ['ACTIVE'] }).pipe(map(sub => {
            // this.beh.mainLoader.next(false);
            if (sub?.responseCode == 'SUCCESS') {
              if (sub.subscriptions.length >= 1) {
                for (let subscription of sub.subscriptions) {
                  if (subscription.status == 'ACTIVE' && (this.global.propConfs$['sub.access.notbillet'] || subscription.billed) || (this.global.propConfs$['sub.access.grace'] && subscription.grace)) {
                    this.beh.userSubscription.next(subscription);
                    return true;
                  } else if ((!!~this.global.propConfs$['sub.access.status'].indexOf(subscription.status) && !this.global.propConfs$['sub.access.notbillet'] && !subscription.billed) || !!~this.global.propConfs$['sub.access.pending'].indexOf(subscription.status)) {
                    this.beh._userPopUp.next('subscribePending');
                    return false;
                  }
                }
                this.beh._userPopUp.next('noCredit');
                return false;
              } else {
                this.beh._userPopUp.next('subscribe');
                return false;
              }
            } else if (sub?.responseCode == 'NOT_SUBSCRIBED') {
              this.beh._userPopUp.next('subscribe');
              return false;
            } else {
              this.beh._userPopUp.next('subscribeError');
              return false;
            }
          }));
        } else {
          return true;
        }

      } else {
        !this.global.propConfs$.allowAccess && this.beh._userPopUp.next(this.global.propConfs$.otp ? 'insertMsisdn' : 'subscribe');
        return this.global.propConfs$.allowAccess ? true : false;
      }
    } else {
      this.router.navigate(segments.reduce((a, c) => { return [...a, c.path] }, ['game']).filter((v, i) => { return i != 1 }));
      this.canLoad;
    }
  }
}
