import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/_core/global.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  public lang = new BehaviorSubject<string>(null);

  constructor(
    private translateService: TranslateService,
    private global: GlobalService,
    private router: Router
  ) { }

  public setLang() {
    this.translateService.setDefaultLang(this.global.propConfs$.langs[0]);

    let user = JSON.parse(localStorage.u || '{}');
    if (this.global.propConfs$.langs.length > 1 && user.l && !!~this.global.propConfs$.langs.indexOf(user.l)) {
      this.lang.next(user.l);
      this.translateService.use(user.l);
      return user.l;
    } else {
      user.l = this.global.propConfs$.langs[0];
      localStorage.u = JSON.stringify(user);
      this.lang.next(user.l);
      this.translateService.use(user.l);
      return this.global.propConfs$.langs[0];
    }
  }

  public changeLang(lang) {
    let user = JSON.parse(localStorage.u || '{}');
    user.l = lang;
    this.translateService.use(user.l);
    this.lang.next(user.l);
    localStorage.u = JSON.stringify(user);

    // location.href = location.href;
    location.href = `${location.protocol}//${location.host}`;
    // this.router.navigateByUrl('/RefreshComponent', { queryParamsHandling: 'merge', skipLocationChange: true }).then(() => { this.router.navigate(['/'], { queryParamsHandling: 'merge' }) });
  }
}
