import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CallInterceptor } from 'projects/authentication/_interceptors/call.interceptor';
import { ApiCallsInterceptor } from 'src/app/_core/_interceptors/api-calls.interceptor';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

declare let siteName: any;

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    SlickCarouselModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    SlickCarouselModule,
    TranslateModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CallInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiCallsInterceptor, multi: true }
  ]
})
export class SharedModule { }

export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, `/product-conf/${siteName()},playbox.global.default/object/langs.`, '/plain');
}