import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalService } from "src/app/_core/global.service";
import { EventBody } from "src/app/_core/_models/event-params.model";

declare let gtag: any;

@Injectable({
  providedIn: 'root'
})

export class EventsService {

  constructor(private global: GlobalService, private http: HttpClient) { }

  public eventStart(params: EventBody) {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', }) }

    params.returningDevice = params.returningDevice || this._returningCheck();
    params.returningUser = params.returningUser || this._returningCheck();
    params.sessionKey = params.sessionKey || this.global.generateSessionId() || undefined;
    params.deviceUuid = params.deviceUuid || this.global.generateDeviceId() || undefined;
    params.operatorId = params.operatorId || this.global.propConfs$.operatorId || undefined;
    params.serviceId = params.serviceId || this.global.propConfs$.serviceId || undefined;
    params.useSession = params.useSession || false;
    params.url = params.url || window.location.href;
    params.referer = params.referer || document.referrer;
    params.sitename = params.sitename || window.location.host;
    params.userAgent = params.userAgent || navigator.userAgent;
    params.msisdn = params.msisdn || (!!JSON.parse(sessionStorage.u || '{}').m ? atob(JSON.parse(sessionStorage.u).m) : undefined);
    params.eventDateClient = params.eventDateClient || new Date().getTime();
    params.trafficTypeId = params.trafficTypeId || this._trafficType(true);
    params.trafficTypeName = params.trafficTypeName || this._trafficType();
    params.connectionType = params.connectionType || this._connection() ? this._connection().type : undefined;
    params.connectionEffectiveType = params.connectionEffectiveType || this._connection() ? this._connection().effectiveType : undefined;
    params.userAttempts = params.userAttempts || this._seeAttempts();
    params.platformType = params.platformType || 'WEB';

    // console.debug(params);
    !!this.global.propConfs$.event.analyticsId && params.eventCategory && gtag('event', params.eventCategory.toLowerCase(), { 'event_category': params.eventType });
    this.global.propConfs$.event.timweBI && this.http.post(`${this.global.relativeUrl}/event/${params.eventType}`, params, httpOptions).toPromise();
  }

  private _trafficType(id = false) {
    let userSession = JSON.parse(sessionStorage.u || '{}');
    let traficsType: any = { 1: '3G', 2: 'WIFI', 3: 'FORWARD', 4: 'RETURN' };
    return userSession.tt ? id ? +userSession.tt : traficsType[userSession.tt] : undefined;
  }

  private _connection() {
    return navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection'] || undefined;
  }

  private _returningCheck() {
    let userSess = JSON.parse(sessionStorage.u || '{}');
    if (userSess.rtn === true || userSess.rtn === false) {
      return userSess.rtn;
    } else {
      userSess.rtn = localStorage.u && JSON.parse(localStorage.u).d ? true : false;
      sessionStorage.u = JSON.stringify(userSess);
      return userSess.rtn;
    }
  }

  /*-- required for this project only (for now)--*/
  private _seeAttempts() {
    let userSession = JSON.parse(sessionStorage.u || '{}');
    return userSession.at ? +atob(userSession.at) : undefined;
  }

}