import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalService } from 'src/app/_core/global.service';
import { BehaviorService } from './behavior.service';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public currentUser = new BehaviorSubject<any>(JSON.parse(localStorage.u || '{}'));

  constructor(
    private global: GlobalService,
    private beh: BehaviorService,
    private events: EventsService
  ) { }

  public doLogin(msisdn, forceProfileShow = false) {
    this.events.eventStart({ eventType: 'LOGIN_SUCCESS', eventCategory: 'SUCCESS' });
    let userSession = JSON.parse(sessionStorage.u || '{}');

    userSession.m = btoa(msisdn);
    sessionStorage.u = JSON.stringify(userSession);
    this.currentUser.next(userSession);

    location.protocol == 'https:' && this.global.initPush(msisdn);

    setTimeout(() => {
      if ((forceProfileShow || userSession.in == undefined) && this.global.propConfs$.otp) { this.beh._userPopUp.next('profile') };
      userSession.in = true;
      sessionStorage.u = JSON.stringify(userSession);
    }, 1000);
  }

  public doLogOut() {
    this.events.eventStart({ eventType: 'LOGOUT_SUCCESS', eventCategory: 'SUCCESS' });
    let user = JSON.parse(localStorage.u || '{}');
    let userSession = JSON.parse(sessionStorage.u || '{}');

    delete userSession.in;
    delete userSession.m;
    delete user.j;
    sessionStorage.u = JSON.stringify(userSession);
    localStorage.u = JSON.stringify(user);
    this.currentUser.next(user);
    location.href = `${location.protocol}//${location.host}`;
  }
}
