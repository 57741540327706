import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

import { LangService } from "src/app/_core/_services/lang.service";
import { BehaviorService } from "src/app/_core/_services/behavior.service";
import { EventsService } from 'src/app/_core/_services/events.service';
import { GlobalService } from './_core/global.service';
import { DoOptinService } from './_core/_services/do-optin.service';
import { ProductConfigsService } from './_core/_services/product-configs.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public propsLoaded$: boolean;

  constructor(
    private lang: LangService,
    private router: Router,
    private global: GlobalService,
    private optin: DoOptinService,
    private confs: ProductConfigsService,
    private beh: BehaviorService,
    private events: EventsService,
    private title: Title
  ) {
    /*-- DO CALL TO CONF FILE --*/
    this.propsLoaded$ = null;
    this.confs.portalConf().subscribe(props => {
      if (props) {
        this.propsLoaded$ = true;
        // console.debug(this.global.propConfs$);
        this.title.setTitle(this.global.propConfs$.portalName);
        !!this.global.propConfs$.favicon && ((document.getElementById('favicon') as any).href = this.global.propConfs$.favicon);

        this.setColorsVariables(this.global.propConfs$.colors);
        !!this.global.propConfs$.event.analyticsId && this.injectAnalytics(this.global.propConfs$.event.analyticsId);
        window.location.protocol == 'https:' && this.global.initPush();

        this.lang.setLang();
        this.events.eventStart({ eventType: 'APP_INIT', eventCategory: 'INIT' });
        this.optin.init();

        this.router.events.subscribe(events => {
          (events instanceof NavigationStart) && this.beh.toggleSideNav(false);
          (events instanceof NavigationEnd) && window.scrollTo(0, 0);
        });
      } else {
        this.propsLoaded$ = false;
        /*-- SHOW CONNECTION ERROR MSG --*/
      }
    });
  }

  private setColorsVariables(colors) {
    const hexToRgb = (hex) => {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }

    document.body.style.setProperty('--mainColor', colors.mainColor);
    document.body.style.setProperty('--secoColor', colors.secoColor);
    document.body.style.setProperty('--thirColor', colors.thirColor);
    document.body.style.setProperty('--neutColor1', colors.neutColor1);
    document.body.style.setProperty('--neutColor2', colors.neutColor2);
    document.body.style.setProperty('--neutColor3', colors.neutColor3);
    document.body.style.setProperty('--neutColor4', colors.neutColor4);
    document.body.style.setProperty('--neutColor5', colors.neutColor5);
    document.body.style.setProperty('--neutColor6', colors.neutColor6);
    document.body.style.setProperty('--neutColor1-0', `rgba(${hexToRgb(colors.neutColor1).r}, ${hexToRgb(colors.neutColor1).g}, ${hexToRgb(colors.neutColor1).b}, 0)`);
    document.body.style.setProperty('--neutColor1-50', `rgba(${hexToRgb(colors.neutColor1).r}, ${hexToRgb(colors.neutColor1).g}, ${hexToRgb(colors.neutColor1).b}, 0.5)`);
    document.body.style.setProperty('--neutColor1-75', `rgba(${hexToRgb(colors.neutColor1).r}, ${hexToRgb(colors.neutColor1).g}, ${hexToRgb(colors.neutColor1).b}, 0.75)`);
    document.body.style.setProperty('--neutColor2-15', `rgba(${hexToRgb(colors.neutColor2).r}, ${hexToRgb(colors.neutColor2).g}, ${hexToRgb(colors.neutColor2).b}, 0.15)`);
    document.body.style.setProperty('--neutColor2-25', `rgba(${hexToRgb(colors.neutColor2).r}, ${hexToRgb(colors.neutColor2).g}, ${hexToRgb(colors.neutColor2).b}, 0.25)`);
    document.body.style.setProperty('--neutColor2-35', `rgba(${hexToRgb(colors.neutColor2).r}, ${hexToRgb(colors.neutColor2).g}, ${hexToRgb(colors.neutColor2).b}, 0.35)`);
    document.body.style.setProperty('--neutColor2-75', `rgba(${hexToRgb(colors.neutColor2).r}, ${hexToRgb(colors.neutColor2).g}, ${hexToRgb(colors.neutColor2).b}, 0.75)`);
    document.body.style.setProperty('--neutColor3-30', `rgba(${hexToRgb(colors.neutColor3).r}, ${hexToRgb(colors.neutColor3).g}, ${hexToRgb(colors.neutColor3).b}, 0.3)`);
    document.body.style.setProperty('--neutColor4-50', `rgba(${hexToRgb(colors.neutColor4).r}, ${hexToRgb(colors.neutColor4).g}, ${hexToRgb(colors.neutColor4).b}, 0.5)`);
    document.body.style.setProperty('--neutColor5-50', `rgba(${hexToRgb(colors.neutColor5).r}, ${hexToRgb(colors.neutColor5).g}, ${hexToRgb(colors.neutColor5).b}, 0.5)`);
    document.body.style.setProperty('--neutColor6-75', `rgba(${hexToRgb(colors.neutColor6).r}, ${hexToRgb(colors.neutColor6).g}, ${hexToRgb(colors.neutColor6).b}, 0.75)`);
  }

  private injectAnalytics(id) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${id}');`;
    let includeScript = document.createElement('script');
    includeScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    includeScript.async = true;
    document.head.appendChild(includeScript);
    document.head.appendChild(script);
  }
}