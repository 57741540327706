import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/_core/global.service';
import { Authentication } from 'projects/authentication/auth.core';
import { LoginService } from './login.service';
import { EventsService } from './events.service';
import { BehaviorService } from './behavior.service';

@Injectable({
  providedIn: 'root'
})
export class DoOptinService {

  constructor(
    private global: GlobalService,
    private auth: Authentication,
    private loginSvc: LoginService,
    private event: EventsService,
    private beh: BehaviorService
  ) { }

  public init() {
    let searchParams = new URLSearchParams(location.search);
    // let userSess = JSON.parse(sessionStorage.u || '{}');

    this.auth.optin({ msisdnToken: searchParams.get(this.global.propConfs$.queryToken) }).subscribe(authUser => {/* , !!userSess.m */
      let user = JSON.parse(localStorage.u || '{}');
      // this.beh.mainLoader.next(false);

      /* CHECK IF FLOW SHLOULD CONTINUE TO HTTPS */
      if (location.protocol == 'http:' && this.global.propConfs$.haveHttps && !searchParams.has('ut')) {
        location.href = `http${location.hostname == 'localhost' ? '' : 's'}://${location.host}?ut=${btoa(user.j)}`;
      } else {
        !!authUser?.products && (user.c = authUser.products);
        !!user.c && this.beh.portalClubs.next(user.c);
        localStorage.u = JSON.stringify(user);

        if (authUser?.responseCode === 'SUCCESS' && authUser.accessStatus === 'LOGGED') {
          if (!!authUser.msisdn) {
            this.event.eventStart({ eventType: 'LOGIN', eventCategory: 'ACCESS' });
            this.loginSvc.doLogin(authUser.msisdn);
            this.validateTraficType();
          }

        } else if (authUser?.accessStatus === 'EXPIRED') {
          this.loginSvc.doLogOut();
        } else {
          /*-- DO NOTHING FOR NOW --*/
          // this.beh.showAuthPopUp.next(this.global.allowOtpFlow ? 'register' : 'no3GConect');
        }
      }
    });
  }

  private validateTraficType() {
    let userSess = JSON.parse(sessionStorage.u || '{}');
    if (!~[1, 2, 3, 4].indexOf(userSess.tt)) {
      userSess.tt = 4;
      sessionStorage.u = JSON.stringify(userSess);
      this.event.eventStart({ eventType: 'LOGIN', eventCategory: 'ACCESS' });
      this.event.eventStart({ eventType: 'LOGIN_SUCCESS', eventCategory: 'SUCCESS' });
    }
  }
}
